import NewslleterService from "../services/b8one.newsletterService";
import Application from "./app";

/**
 * Cart Abstraction class
 */
class NewsletterApp extends Application {
    constructor() {
        super()

        /** @private @const {NewslleterService} */
        this.newsletterService_ = new NewslleterService();
    }

    async enableUserNewsletterByEmail(email, name) {
        return await this.newsletterService_.setNewsletterByEmail(email, name);
    }
}

export default NewsletterApp;
