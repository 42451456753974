import CartService from '../services/b8one.cartService';
import Component from './b8one.component';

import { REAL } from '../../general/utils/currency';

export default class GroceryMiniCart extends Component {
  constructor() {
    super();

    this.cartService = new CartService();

    this.cartMiniListClass = '.js-minicart-list';
    this.cartMiniCountClass = '.js-minicart-count';
    this.cartMiniQuantityButtonClass = '.js-minicart-quantity';
    this.cartMiniRemoveButton = '.js-minicart-product-remove';

    this.cartMiniInput = '.js-minicart-inputSearch';
    this.cartMiniSubmit = '.js-minicart-inputSubmit';

    this.orderForm = {};
    this.departaments = {};

    this.productsCount = 0;

    /**
     * Get Mini Cart List Element
     */

    this.getMiniCartListElement = () => this.getElement(this.cartMiniListClass);

    /**
     * Get Mini Cart Count Element
     */

    this.getMiniCartCountElement = () =>
      document.querySelectorAll(this.cartMiniCountClass);

    this.load();
  }

  async getData() {
    this.orderForm = await this.cartService.getCurrent();

    this.listDepartaments(this.orderForm);
  }

  listDepartaments({ items }) {
    this.departaments = {};

    items.forEach((item, idx) => {
      const departamentName =
        item.productCategories[Object.keys(item.productCategories)[0]];
      const product = { ...item, idx };

      if (departamentName in this.departaments) {
        const currentDepartament = this.departaments[departamentName];

        this.departaments = {
          ...this.departaments,
          [departamentName]: {
            ...currentDepartament,
            products: [...currentDepartament.products, product],
          },
        };
      } else {
        this.departaments = {
          ...this.departaments,
          [departamentName]: {
            products: [product],
          },
        };
      }
    });
  }

  async refreshCart() {
    await this.getData();

    document.querySelector(this.cartMiniListClass).innerHTML = '';

    this.listDepartaments(this.orderForm);
    this.countTotalProducts();
    this.updatePriceLabel();
    this.renderDepartament();
    this.handlerQuantity();
    this.removeProduct();
  }

  debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function() {
        timeout = null;

        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  }

  renderDepartament() {
    document.querySelector(this.cartMiniListClass).innerHTML = '';

    for (let departamentName in this.departaments) {
      const departamentNameFormatted = departamentName
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
      const departamentProducts = this.departaments[departamentName].products;

      this.getMiniCartListElement().insertAdjacentHTML(
        'beforeend',
        `
        <li class="cartProduct__departament">
          <div class="cartProduct__departament_header">
            <span class="cartProduct__departament_name cartProduct__departament_name--${departamentNameFormatted}">
              ${departamentName}
            </span>
          </div>
          <ul class="cartProcuct__list">
            ${this.renderProduct(departamentProducts)}
          </ul>
        </li>
      `
      );
    }
  }

  renderProduct(products) {
    return products
      .map(
        ({
          imageUrl,
          price,
          listPrice,
          name,
          idx,
          quantity,
          detailUrl,
          id,
        }) => `
          <li class="cartProduct__item" data-product-id="${id}" data-product-idx="${idx}">
            <div class="cartProduct__image">
              <img class="cartProduct__img" src="${imageUrl}" alt="${name}"/>
            </div>
            <div class="cartProduct__info">
              <a class="cartProduct__name" href="${detailUrl}">
                ${name}
              </a>
              <div class="cartProduct__quantity" data-product-idx="${idx}">
                <button class="cartProduct__quantity--button cartProduct__quantity--minus js-minicart-quantity" data-quantity-factor="-1">
                  <svg class="cartProduct__icon cartProduct__icon--minus"  width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="8" height="2" rx="1" fill="#9692A3"/>
                  </svg>
                </button>
                <input class="cartProduct__input" type="number" value="${quantity}" min="1" />
                <button class="cartProduct__quantity--button cartProduct__quantity--plus js-minicart-quantity" data-quantity-factor="+1">
                  <svg class="cartProduct__icon cartProduct__icon--plus" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C3.44772 0 3 0.447715 3 1V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H3V7C3 7.55228 3.44772 8 4 8C4.55228 8 5 7.55228 5 7V5H7C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3H5V1C5 0.447715 4.55228 0 4 0Z" fill="#9692A3"/>
                  </svg>
                </button>
              </div>
              <div class="cartProduct__price">
                <span class="cartProduct__price--new">
                  ${REAL(this.helper_.formatPrice(price))
                    .multiply(quantity)
                    .format()}
                </span>
              </div>
              <button class="cartProduct__remove js-minicart-product-remove">
                <svg class="cartProduct__icon cartProduct__icon--remove" width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.32812 12.125H7.98438C8.14844 12.125 8.3125 11.9883 8.3125 11.7969V5.89062C8.3125 5.72656 8.14844 5.5625 7.98438 5.5625H7.32812C7.13672 5.5625 7 5.72656 7 5.89062V11.7969C7 11.9883 7.13672 12.125 7.32812 12.125ZM11.8125 2.9375H9.54297L8.61328 1.40625C8.39453 1.05078 7.90234 0.75 7.49219 0.75H4.73047C4.32031 0.75 3.82812 1.05078 3.60938 1.40625L2.67969 2.9375H0.4375C0.191406 2.9375 0 3.15625 0 3.375V3.8125C0 4.05859 0.191406 4.25 0.4375 4.25H0.875V13.4375C0.875 14.1758 1.44922 14.75 2.1875 14.75H10.0625C10.7734 14.75 11.375 14.1758 11.375 13.4375V4.25H11.8125C12.0312 4.25 12.25 4.05859 12.25 3.8125V3.375C12.25 3.15625 12.0312 2.9375 11.8125 2.9375ZM4.67578 2.14453C4.70312 2.11719 4.78516 2.0625 4.8125 2.0625H4.83984H7.41016C7.4375 2.0625 7.51953 2.11719 7.54688 2.14453L8.01172 2.9375H4.21094L4.67578 2.14453ZM10.0625 13.4375H2.1875V4.25H10.0625V13.4375ZM4.26562 12.125H4.92188C5.08594 12.125 5.25 11.9883 5.25 11.7969V5.89062C5.25 5.72656 5.08594 5.5625 4.92188 5.5625H4.26562C4.07422 5.5625 3.9375 5.72656 3.9375 5.89062V11.7969C3.9375 11.9883 4.07422 12.125 4.26562 12.125Z" fill="#9D9D9D"/>
                </svg>

              </button>
            </div>
          </li>
        `).join('');
  }

  updatePriceLabel() {
    const priceElement = document.querySelector('.js-minicart-value');

    const price = this.orderForm.value;

    console.log('----');
    console.log(priceElement);

    priceElement.innerHTML = this.helper_.formatPrice(price);
  }

  countDepartamentProducts(products) {
    let count = 0;

    products.forEach(({ quantity }) => {
      count = count + quantity;
    });

    return count;
  }

  countTotalProducts() {
    this.productsCount = 0;

    const { items } = this.orderForm;

    items.forEach(({ quantity }) => {
      this.productsCount = this.productsCount + quantity;
    });

    this.renderCountTotalProducts();
  }

  renderCountTotalProducts() {
    this.getMiniCartCountElement().forEach(element => element.textContent = this.productsCount)
  }

  handlerQuantity() {
    const buttonQuantity = this.getAllElements(
      this.cartMiniQuantityButtonClass
    );

    buttonQuantity.forEach(button => {
      button.addEventListener('click', async _event => {
        const idx = button.parentElement.dataset.productIdx;
        const inputValue = button.parentElement.children[1].value;
        const totalQuantity =
          parseInt(inputValue) + parseInt(button.dataset.quantityFactor);

        this.updateProduct(idx, button.dataset.quantityFactor);

        button.parentElement.children[1].value =
          totalQuantity <= 0 ? inputValue : totalQuantity;
      });
    });
  }

  updateProduct(index, factor) {
    const { items } = this.orderForm;

    const updateOrderForm = this.debounce(async (index, factor) => {
      var item = {
        index: index,
        quantity: items[index].quantity + parseInt(factor),
      };

      await window.vtexjs.checkout.getOrderForm();
      await window.vtexjs.checkout.updateItems([item], null, false).done(orderForm => {
        console.log('updated OrderForm', orderForm);
        this.orderForm = orderForm;

        this.refreshCart();

        if (item.quantity === 0) {
          const eventRemoveProduct = new CustomEvent('removeProduct', {
            detail: {
              skuId: items[index].id,
              productId: items[index].productId,
            },
          });

          window.dispatchEvent(eventRemoveProduct);
        } else {
          const eventUpdateProductQuantity = new CustomEvent(
            'updateProductQuantity',
            {
              detail: {
                skuId: this.orderForm.items[index].id,
                quantity: this.orderForm.items[index].quantity,
                productId: this.orderForm.items[index].productId,
              },
            }
          );

          window.dispatchEvent(eventUpdateProductQuantity);
        }
      });
    }, 100);

    updateOrderForm(index, factor);
  }

  removeProduct() {
    const removeBtns = document.querySelectorAll(this.cartMiniRemoveButton);    

    [...removeBtns].map(btn => {
      btn.addEventListener('click', async () => {
        const prodId = btn.parentElement.parentElement.dataset.productId;

        const { items } = await window.vtexjs.checkout.getOrderForm();

        const idx = items.findIndex(item => item.id == prodId);
        
        var item = {
          index: idx,
          quantity: items[idx].quantity,
        };

        await window.vtexjs.checkout
          .removeItems([item], null, false)
          .done(orderForm => {
            this.refreshCart(orderForm);

            const eventRemoveProduct = new CustomEvent('removeProduct', {
              detail: {
                skuId: items[idx].id,
                productId: items[idx].productId,
              },
            });

            window.dispatchEvent(eventRemoveProduct);
          });
      });
    });
  }

  async load() {
    await this.getData();
    this.countTotalProducts();
    this.renderDepartament();
    this.handlerQuantity();
    this.updatePriceLabel();
    this.removeProduct();
  }
}
