// grocery-to-go/shoppingList
import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
	lists: [],
	products: {},
	categories: [],
	productId: null,
	selectedList: {},
	showModalAddProduct: false
};

export const { Types, Creators } = createActions(
	{
		addList: {
			id: '',
			title: '',
			user: null,
			totalItems: 0,
			isPublic: false,
			totalPrice: 'R$ 0,00',
			shoppingListCategory: ''
		},
		removeList: ['id'],
		addLists: ['lists'],
		addCategory: {
			id: '',
			name: '',
			user: null,
			isPublic: false
		},
		addCategories: ['categories'],
		addProducts: ['products'],
		updateProductActive: ['skuID', 'isActive'],
		updateProductQuantity: ['skuID', 'quantity'],
		removeProduct: ['skuID'],
		updateSelectedListTotalItems: ['totalItems'],
		updateSelectedListTotalPrice: ['totalPrice'],
		setSelectedList: {
			id: '',
			title: '',
			thumbnail: '',
			totalPrice: 0,
			isPublic: false,
			description: '',
			totalItems: 0
		},
		setShowModalAddProduct: ['showModal'],
		setProductId: ['productId']
	},
	{
		prefix: 'grocery-to-go/shoppingList/'
	}
);

export const addList = produce((draft, action) => {
	const { type, ...list } = action;

	draft.lists.push(list);

	return draft;
});

export const removeList = produce((draft, action) => {
	const { id } = action;

	draft.lists = draft.lists.filter(list => list.id !== id);

	return draft;
});

export const addLists = produce((draft, action) => {
	const { lists } = action;

	draft.lists = lists;

	return draft;
});

export const addCategory = produce((draft, action) => {
	const { type, ...category } = action;

	draft.categories.push(category);

	return draft;
});

export const addCategories = produce((draft, action) => {
	const { categories } = action;

	draft.categories = categories;

	return draft;
});

export const addProducts = produce((draft, action) => {
	const { products } = action;

	draft.products = products;

	return draft;
});

export const setSelectedList = produce((draft, action) => {
	const { type, ...payload } = action;

	draft.selectedList = payload;

	return draft;
});

export const updateProductActive = produce((draft, action) => {
	const { skuID, isActive } = action;

	draft.products[skuID].active = isActive;

	return draft;
});

export const updateProductQuantity = produce((draft, action) => {
	const { skuID, quantity } = action;

	draft.products[skuID].quantity = quantity;

	return draft;
});

export const updateSelectedListTotalItems = produce((draft, action) => {
	const { totalItems } = action;

	draft.selectedList.totalItems = totalItems;

	return draft;
});

export const updateSelectedListTotalPrice = produce((draft, action) => {
	const { totalPrice } = action;

	draft.selectedList.totalPrice = totalPrice;

	return draft;
});

export const setShowModalAddProduct = produce((draft, action) => {
	const { showModal } = action;

	draft.showModalAddProduct = showModal;

	return draft;
});

export const setProductId = produce((draft, action) => {
	const { productId } = action;

	draft.productId = productId;

	return draft;
});

export const removeProduct = produce((draft, action) => {
	const { skuID } = action;

	delete draft.products[skuID];

	return draft;
});

const HANDLERS = {
	[Types.ADD_LIST]: addList,
	[Types.REMOVE_LIST]: removeList,
	[Types.ADD_LISTS]: addLists,
	[Types.ADD_CATEGORY]: addCategory,
	[Types.ADD_CATEGORIES]: addCategories,
	[Types.ADD_PRODUCTS]: addProducts,
	[Types.SET_PRODUCT_ID]: setProductId,
	[Types.REMOVE_PRODUCT]: removeProduct,
	[Types.SET_SELECTED_LIST]: setSelectedList,
	[Types.UPDATE_PRODUCT_ACTIVE]: updateProductActive,
	[Types.UPDATE_PRODUCT_QUANTITY]: updateProductQuantity,
	[Types.SET_SHOW_MODAL_ADD_PRODUCT]: setShowModalAddProduct,
	[Types.UPDATE_SELECTED_LIST_TOTAL_ITEMS]: updateSelectedListTotalItems,
	[Types.UPDATE_SELECTED_LIST_TOTAL_PRICE]: updateSelectedListTotalPrice
};

export default createReducer(INITIAL_STATE, HANDLERS);
