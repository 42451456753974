// Dependencies
import { combineReducers } from 'redux';

// Reducers
import user from '../ducks/user';
import shoppingList from '../ducks/shoppingList';

export default combineReducers({
	user,
	shoppingList
});
