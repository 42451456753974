class Application {
    constructor() {
        this.props = {
            language: navigator.language || navigator.userLanguage,
            currency: 'BRL',
            currencyLocale: 'pt-br',
            minimumFractionDigits: 2,
            storeId: 'autosuperstore',
        };
    }
}

export default Application;
