import Component from "./b8one.component";
import ProductRepository from "../repositories/b8one.productRepository";
import { Ensure } from "../common";

class ProductCardComponent extends Component {
  constructor() {
    super();

    this.productRepository_ = new ProductRepository();

    this.productCardClass = "productCard";

    this.buyButtonClass = '.js-product-card-button';
    this.quantityActionClass = '.js-product-card-quantity-action';
    this.quantityValueClass = '.js-product-card-quantity'

    this.buttonElements = () => this.getAllElements(this.buyButtonClass);
  }

  formatterImageTag(imageProp = {}, secundary = false) {
    const { imageId, imageText, imageUrl } = imageProp;
    const imageFormat = imageUrl.split(".")[4].split("?")[0];
    const urlFormatted = `/arquivos/ids/${imageId}-500-600/${imageText}.${imageFormat}`;
    const secundaryClass = `${this.productCardClass}__img--secundary`;

    return `<img src="${urlFormatted}" class="${
      secundary ? secundaryClass : ""
    }" />`;
  }

  renderCard(product) {
    const { items } = product;
    const { commertialOffer: { Price, Installments, ListPrice }} = items[0].sellers[0];

    const stockCondition = className => {
      return Price > 0
        ? `productCard__${className}--hidden`
        : `productCard__${className}--visible`;
    };

    const tagCondition = className => {
      return Price !== ListPrice
        ? `productCard__${className}--visible`
        : `productCard__${className}--hidden`;
    };

    return `
      <article class="productCard" data-product-id="${product.link}">
        <a class="productCard__link" href="${product.link}">
          <div class="productCard__image js-shelf-product">
            ${this.formatterImageTag(items[0].images[0])}
          </div>
        </a>
        <a class="productCard__link" href="${product.link}">
          <h3 class="productCard__name">
            ${product.productName}
          </h3>
        </a>
        <div class="productCard__price">
          <span class="productCard__price--old ${tagCondition("price")}">R$ ${ListPrice.toFixed(2).replace(".", ",")}</span>
          <span class="productCard__price--best">R$ ${Price.toFixed(2).replace(".", ",")}</span>  
          <span class="productCard__installments">Em até <span class="productCard__installments--strong">${this.renderInstallments(Installments)}</span></span>
        </div>
        <span class="productCard__unavailable ${stockCondition("unavailable")}">Indisponível</span>
        <a class="productCard__button js-product-card-button" href="${product.link}">
          Comprar
        </a>
      </article>
    `;
  }

  renderInstallments(installs) {
    const {NumberOfInstallments } = installs.reduce(
      (prev, current) =>
        prev.NumberOfInstallments > current.NumberOfInstallments
          ? prev
          : current,
      0
    );

    return `<span class="productCard__installments">${NumberOfInstallments}x sem juros</span>`;
  }

  buttonHandler() {
    this.buttonElements().forEach(button => {
      button.addEventListener('click', async _event => {
        _event.preventDefault();
        button.classList.add('productCard__button--loading');
        
        const productId = button.parentElement.dataset.productId;
        const skuId = await this.productRepository_.getBySkuId(productId);
        const { items } = await this.addToCart(skuId.items[0].itemId);

        button.classList.remove('productCard__button--loading');
        button.outerHTML = this.renderQuantity(this.idxProductIncart(id, items));
        this.handlerQuantity();
      });
    });
  }

  async addToCart(id) {
    await vtexjs.checkout.getOrderForm();
    const res = await vtexjs.checkout.addToCart([{id, quantity: 1, seller: '1'}], null, 1);

    return res;
  }

  async updateCart(itemIdx, quantity, remove = false) {
    await vtexjs.checkout.getOrderForm();

    const updatedItem = {
      index: itemIdx,
      quantity: quantity
    };

    if(!remove) {
      const res = await vtexjs.checkout.updateItems([updatedItem], null, false);

      return res;
    } else {
      const res = await vtexjs.checkout.removeItems([updatedItem]);

      return res;
    }
  }


  renderQuantity({idx, quantity}) {
    return `
      <div class="productCard__quantity" data-product-idx="${idx}">
        <a class="productCard__quantity_action js-product-card-quantity-action" href="javascript:;" data-quantity-action="minus">
          <svg class="productCard__icon productCard__icon--minus" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="2" rx="1" fill="#9692A3"/>
          </svg>
        </a>
        <span class="productCard__quantity_number js-product-card-quantity">
          ${quantity}
        </span>
        <a class="productCard__quantity_action js-product-card-quantity-action" href="javascript:;" data-quantity-action="plus">
          <svg class="productCard__icon productCard__icon--plus" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1C7 0.447715 6.55228 0 6 0Z" fill="#9692A3"/>
          </svg>
        </a>
      </div>
    `
  }

  handlerQuantity() {
    const quantityButtonElements = document.querySelectorAll(this.quantityActionClass);
    const quantityValueElement = document.querySelector(this.quantityValueClass);

    [...quantityButtonElements].forEach(button => {
      button.addEventListener('click', async _event => {
        _event.preventDefault();

        const { quantityAction } = button.dataset;
        const { productIdx } = button.parentNode.dataset;
        const currentQuantity = parseInt(quantityValueElement.innerText);
        const isLoading = (loading) => {
         (loading ? button.parentNode.classList.add('productCard__quantity--loading') 
         : 
         button.parentNode.classList.remove('productCard__quantity--loading'));
        }

        if(quantityAction == 'plus') {
          const quantityChanged = currentQuantity + 1;

          isLoading(true);

          await this.updateCart(productIdx, quantityChanged);

          quantityValueElement.innerText = quantityChanged; 

          isLoading(false);
        } else {
          const quantityChanged = currentQuantity == 1 ? currentQuantity : currentQuantity - 1;

          (quantityChanged >= 2) ? isLoading(true) : '';

          if(quantityChanged >= 1) {

            await this.updateCart(productIdx, quantityChanged);
          }

          quantityValueElement.innerText = quantityChanged;

          setTimeout(() => {
            isLoading(false);
          }, 500);
        }
      });
    });
  }

  idxProductIncart(id, items) {
    let productInfo;

    for (let idx = 0; idx < items.length; idx++) {
      const { productId, quantity } = items[idx];

      if(productId == id) {
        productInfo = { idx, quantity };
        break;
      } else {
        productInfo = { idx: items.length, quantity };
      }
    }

    return productInfo;
  }

  load() {
    this.buttonHandler();
  }
}

export default ProductCardComponent;
