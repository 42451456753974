/* eslint-disable max-len */
import HttpClient from "../services/b8one.httpClient";

/**
 * ProductRepository data access layer
 */
class ProductRepository {
  /**
   * ProductRepository class instance
   * @param {HttpClient} httpClient
   */
  constructor() {
    this._VTEX_API_ENDPOINT_SEARCH = "/api/catalog_system/pub/products/search";
    this._VTEX_API_ENDPOINT_CROSSSELING =
      "/api/catalog_system/pub/products/crossselling/";
    this._httpClient = new HttpClient();
  }

  /**
   * Get product by SKU Id
   * @param {Number} skuId - Product SKU Id
   * @return {Promise}
   */
  async getBySkuId(skuId = 0) {
    return this._httpClient
      .get(`${this._VTEX_API_ENDPOINT_SEARCH}/?fq=productId:${skuId}`)
      .then(product => {
        return product[0];
      });
  }

  async getProductByBrand(brandId, params) {
    return this._httpClient
      .get(`${this._VTEX_API_ENDPOINT_SEARCH}/?fq=B:${brandId}${params}`)
      .then(product => {
        return product;
      });
  }

  async getSpecificationByProductId(skuId = 0, specification) {
    return this._httpClient
      .get(`${this._VTEX_API_ENDPOINT_SEARCH}/?fq=productId:${skuId}`)
      .then(product => {
        return product[0][specification];
      });
  }

  async getSimilarBySkuId(skuId = 0) {
    return this._httpClient.get(
      `${this._VTEX_API_ENDPOINT_CROSSSELING}/similars/${skuId}`
    );
  }

  async getImagesBySkuId(skuId = 0) {
    return this._httpClient
      .get(`${this._VTEX_API_ENDPOINT_SEARCH}/?fq=productId:${skuId}`)
      .then(product => {
        return product[0].items[0].images;
      });
  }

  /**
   * Get product by params
   * @param {string} params - query string
   * @return {Promise}
   */
  async getByParams(params = 0) {
    return await this._httpClient.get(
      `${this._VTEX_API_ENDPOINT_SEARCH}/?${params}`
    );
  }
}

export default ProductRepository;
