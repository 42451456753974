/* eslint-disable max-len */

import {Ensure, Logger} from '../common';
import HttpClient from './b8one.httpClient';

/**
 * Cart Abstraction class
 */
class CartService {
  /**
   * Cart constructor
   */
  constructor() {
    this._VTEX_API_ENDPOINT = '/api/checkout/pub/orderForm';

    this._logger = new Logger('Cart service');
    this._httpClient = new HttpClient();
  }

  /**
   * Get current cart
   * @return {*} Cart object
   */
  async getCurrent() {
    return await this._httpClient.get(this._VTEX_API_ENDPOINT);
  }

  /**
   * Add item to cart
   * @param {*} skuId - Product sku Id (SKU)
   * @param {*} productQuantity - Product Quantity
   * @param {*} sellerId - Seller id
   */
  async add(skuId, productQuantity, sellerId = 1) {
    // const payload = this._payloadFactory(skuId, productQuantity, sellerId);

    if (!Ensure.isUndefined(skuId)) {
      const current = await this.getCurrent();
      const indexFound =
        current.items.findIndex((x) => x.id === skuId);

      if (indexFound > -1) {
        productQuantity = parseInt(productQuantity) +
          parseInt(current.items[indexFound].quantity);
      }

      const endpointUrl =
        `${this._VTEX_API_ENDPOINT}/${current.orderFormId}/items`;

      const payload = {
        orderItems: [
          {
            'seller': sellerId.toString(),
            'quantity': parseInt(productQuantity),
            'id': skuId.toString(),
          },
        ],
      };

      return this._httpClient.patch(endpointUrl, payload);
    }
  }

  /**
   * Remove item to cart
   * @param {JSON} itemIndex - Product item index to be removed
   */
  async remove(itemIndex) {
    const current = await this.getCurrent();
    const endpointUrl = `${this._VTEX_API_ENDPOINT}/${current.orderFormId}/items`;
    const payload = {
      orderItems: [{
        'index': itemIndex,
        'quantity': 0,
      }],
      expectedOrderFormSections: ['items'],
      noSplitItem: true,
    };

    await this._httpClient.patch(endpointUrl, payload);
  }

  /**
   * Remove item to cart
   * @param {JSON} payload
   */
  update(payload) {
    this._logger.warning('remove method is not implemented..');
  }

  /**
   * Cart's payload method factory
   * @param {*} skuId - Product Id (SKU)
   * @param {*} productQuantity - Product Quantity
   * @param {*} sellerId - Seller id
   * @return {JSON} cart paylod
   */
  _payloadFactory(skuId, productQuantity, sellerId = 1) {
    return {
      id: skuId,
      quantity: productQuantity,
      seller: sellerId,
    };
  }
}

export default CartService;
