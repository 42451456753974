/* eslint-disable max-len, no-undef */

import Component from './b8one.component';
import VtexUser from '../services/b8one.vtexUser';
import {Ensure} from '../common';

/**
 * LoginComponent Abstraction class
 */
class LoginComponent extends Component {
  /**
   * constructor
   */
  constructor() {
    super();

    this._vtexUser = new VtexUser();
    this.userLoginSelector = '.js-account';
    this.userLoginButtonSelector = '.js-account-login';
    this.userIsLogged = false;
  }

  /**
   * load component
   */
  async load() {
    await this.getUser();
    this.eventHandlerBinds();
  }

  /**
   * Component's render method
   */
  async getUser() {
    const { IsUserDefined } = await this._vtexUser.getCurrent();

    this.userIsLogged = IsUserDefined;
  }

  /**
   * Open user login modal
   */
  userLogin() {
    vtexid.start({
      locale: this.props.language,
      forceReload: true,
    });
  }

  /**
   * Get User's display name
   * @param {*} userObj - Vtex User JSON Object
   * @return {String} User's display name
   */
  getUserDisplayName(userObj) {
    return Ensure.isUndefined(userObj.FirstName) ?
      userObj.Email.split('@')[0].split('.')[0] :
      userObj.FirstName;
  }

  /**
   * Event binds
   */
  eventHandlerBinds() {
    const loginButtons = document.querySelectorAll(this.userLoginButtonSelector);

    for (let i = 0; i < loginButtons.length; i++) {
      const loginButton = loginButtons[i];

      loginButton.addEventListener('click', (event) => {
        if(!this.userIsLogged) {
          event.preventDefault();
          this.userLogin();
        }
      }, false);
    }
  }
}

export default LoginComponent;
