// grocery-to-go/user
import produce from 'immer';
import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
	email: ''
};

export const { Types, Creators } = createActions(
	{
		setEmail: ['email']
	},
	{
		prefix: 'grocery-to-go/user/'
	}
);

export const setEmail = produce((draft, action) => {
	draft.email = action.email;

	return draft;
});

const HANDLERS = {
	[Types.SET_EMAIL]: setEmail
};

export default createReducer(INITIAL_STATE, HANDLERS);
