import HttpClient from "../services/b8one.httpClient";

/**
 * Masterdata Base Repository
 */
class MasterdataRepository {
  /**
   * Masterdata Repository constructor
   * @param {*} entityCode
   */
  constructor(entityCode) {
    this.entityCode_ = entityCode;
    this.httpClient_ = new HttpClient();

    // this._VTEX_MASTERDATA_ENDPOINT = `//api.vtexcrm.com.br/${STORE_ID}/dataentities/${entityCode}`;
    this._VTEX_MASTERDATA_ENDPOINT = `/api/dataentities/${entityCode}`;
  }

  /**
   * add
   * @param {*} entityPayload
   * @return {*}
   */
  add(entityPayload) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/documents`;

    return this.httpClient_.put(apiEndpoint, entityPayload).then(
      res => {
        return res;
      },
      error => {
        return error;
      }
    );
  }

  /**
   * update..
   * @param {*} entityPayload
   * @return {*}
   */
  update(entityPayload) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/documents`;

    return this.httpClient_.patch(apiEndpoint, entityPayload).then(
      res => {
        return res;
      },
      error => error
    );
  }

  /**
   * search
   * @param {*} params
   * @return {*}
   */
  search(params, all) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/search?${params}`;

    console.log("search", all);

    return this.httpClient_.get(apiEndpoint, all).then(res => {
      return res;
    });
  }

  /**
   * Get Document by document ID
   * @param {GUID} documentId Document Id
   */
  async getByDocumentId(documentId) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/documents/${documentId}`;

    const res = await this.httpClient_.get(apiEndpoint);
    return res;
  }

  async getByDocumentIdWithFields(documentId, fields) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/documents/${documentId}?_fields=${fields}`;

    const res = await this.httpClient_.get(apiEndpoint);
    return res;
  }
  /**
   * delete
   * @param {*} documentId
   * @return {*}
   */
  delete(documentId) {
    const apiEndpoint = `${this._VTEX_MASTERDATA_ENDPOINT}/documents/${documentId}`;

    return this.httpClient_.delete(apiEndpoint).then(res => {
      return res;
    });
  }

  /**
   *
   * @param {*} documentId
   * @param {*} field
   * @param {*} fileName
   */
  getAttachmentUrl(documentId = "", field = "", fileName = "") {
    return `${this._VTEX_MASTERDATA_ENDPOINT}/documents/${documentId}/${field}/attachments/${fileName}`;
  }

  /**
   * Get attachments
   * @param {*} documentId
   * @param {*} field
   * @param {*} fileName
   */
  async getAttachment(documentId = "", field = "", fileName = "") {
    // /api/dataentities/{{data_entity_name}}/documents/{{id}}/{{field}}/attachments/{{file_name}}
    const apiEndpoint = this.getAttachmentUrl(documentId, field, fileName);

    const res = await this.httpClient_.get(apiEndpoint);
    return res;
  }
}

export default MasterdataRepository;
