import GroceryMiniCart from 'Components/b8one.groceryMiniCartComponent';
import LoginComponent from 'Components/b8one.loginComponent';
import NewsletterComponent from 'Components/b8one.newsletterComponent';
import SearchComponent from 'Components/b8one.searchComponent';
import MenuComponent from 'Components/b8one.menuComponent';

import store from '../../state/store';

window.store = store;

class General {
	constructor() {
		this.minicart_ = new GroceryMiniCart();
		this.login_ = new LoginComponent();
		this.newsletter_ = new NewsletterComponent();
		this.search_ = new SearchComponent();
		this.menu_ = new MenuComponent();

		this.eventHandlerBinds();
	}

	handlers() {
		const cartButtonElements = document.querySelectorAll('.js-cartmini-action');

		[...cartButtonElements].forEach(button => {
			button.addEventListener('click', _event => {
				_event.preventDefault();

				document.body.classList.toggle('action__cart');
			});
		});
	}
 
	eventHandlerBinds() {
		// Page's DOM content is loaded
		document.addEventListener('DOMContentLoaded', event => {
			this.handlers();
			
			this.componentBuilder();
		});
	}

	componentBuilder() {
		this.login_.load();
		this.minicart_.load();
		this.newsletter_.load();
		// this.instagram_.load();

		this.search_.load();
		this.menu_.load();
	}
}

new General();
