import { Ensure, Logger } from '../common';

/**
 * HttpClient Abstraction class
 */
class HttpClient {
  /**
   * HttpClient constructor
   */
  constructor() {
    this._logger = new Logger('httpClient');
  }

  /**
   * Get
   * @param {String} endpointUrl
   * @return {Promise}
   */
  get(endpointUrl) {
    if (!Ensure.isUndefined(endpointUrl)) {
      return this._execute(endpointUrl, 'GET');
    }
  }

  /**
   * Delete
   * @param {String} endpointUrl
   * @return {Promise}
   */
  delete(endpointUrl) {
    if (!Ensure.isUndefined(endpointUrl)) {
      return this._execute(endpointUrl, 'DELETE');
    }
  }

  /**
   *
   * @param {String} endpointUrl
   * @param {JSON} payload
   * @return {Promise}
   */
  post(endpointUrl, payload = {}) {
    if (!Ensure.isUndefined(endpointUrl)) {
      return this._execute(endpointUrl, 'POST', payload);
    }
  }

  /**
   *
   * @param {String} endpointUrl
   * @param {JSON} payload
   * @return {Promise}
   */
  put(endpointUrl, payload) {
    if (!Ensure.isUndefined(endpointUrl) && !Ensure.isUndefined(payload)) {
      return this._execute(endpointUrl, 'PUT', payload);
    }
  }

  /**
   *
   * @param {String} endpointUrl
   * @param {JSON} payload
   * @return {Promise}
   */
  patch(endpointUrl, payload) {
    if (!Ensure.isUndefined(endpointUrl) && !Ensure.isUndefined(payload)) {
      return this._execute(endpointUrl, 'PATCH', payload);
    }
  }

  /**
  * @param {String} endpointUrl
  * @param {String} httpVerb
  * @param {Object} payload
  * @return {Promise}
  */
  _execute(endpointUrl, httpVerb, payload) {
    let header = {
      method: httpVerb,
      headers: {
        // 'Accept': 'application/vnd.vtex.ds.v10+json',
        // 'Content-Type': 'application/json',
        "accept": "application/vnd.vtex.masterdata.v10+json",
        "content-type": "application/json; charset=utf-8"
      },
    };

    if (payload) {
      header['body'] = JSON.stringify(payload);
    }

    return fetch(endpointUrl, header)
      .then(this.validateResponse)
      .then(this.parseResponseToJson)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this._logger.log('Request succeeded with JSON response', error);
        return error;
      });
  }

  /**
   * Parse response data to json object
   * @param {*} response - Request response
   * @return {JSON} json data
   */
  async parseResponseToJson(response) {
    if (!response.ok) {
      return response;
    }

    return await response.json();
  }

  /**
   * Response validation
   * @param {*} response
   * @return {Promise} promise result
   */
  validateResponse(response) {
    if (response.ok) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }
}

export default HttpClient;
