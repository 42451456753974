import Component from "./b8one.component";
import VtexCategoryService from "Services/b8one.VtexCategoryService";

export default class MenuComponent extends Component {
  vtexCategoryService_

  constructor(vtexCategoryService) {
    super();
    this.vtexCategoryService_ = vtexCategoryService ?? new VtexCategoryService();

    this.actionMenuClass = 'action__menu';
    this.openButtonClass = '.js-hamburguer';
    this.listClass = '.js-menu-list';
    this.subClass = '.js-menu-sub';
    this.backClass = '.js-menu-back';
    this.subActiveClass = 'menu__item--active';

    /**
     * Get Button Open Menu Element
     */
    this.openButtonElement = () => this.getElement(this.openButtonClass);
    
    /**
     * Get List Menu Element
     */
    this.listElement = () => this.getElement(this.listClass);
  }

  open() {
    this.openButtonElement().addEventListener('click', _event => {
      _event.preventDefault();

      document.body.classList.toggle(this.actionMenuClass);
    });
  }

  /**
   * Render Menu Children Node (recursivelly)
   * @param node Category's Children Array
   * @return children node HTML element's (UL)
   */
  renderChildren(node = []) {
    let rootNode = document.createElement("ul");
    rootNode.className = 'menu__list'
    
    for (let index = 0; index < node.children.length; index++) {
      const child = node.children[index];
      let nodeItem = document.createElement("li");
      nodeItem.className = 'menu__item';
      
      let nodeLink = document.createElement('a');
      nodeLink.className = 'menu__link menu__link--sub'
      nodeLink.href = child.url
      nodeLink.innerText = child.name
      
      nodeItem.append(nodeLink)

      if(child.hasChildren) {
        let childrenNode = document.createElement("div");
        childrenNode.className = 'menu__sub'
        childrenNode.append(this.renderChildren(child));

        nodeItem.append(childrenNode);
      }

      rootNode.append(nodeItem)
    }

    return rootNode
  }
  
  async renderDepartament() {
    const categories = await this.vtexCategoryService_.getAll();

    this.listElement().innerHTML = '';

    this.listElement().insertAdjacentHTML(
			'beforeend',
			`
      <li class="menu__item menu__item--special menu__item--dad">
        <a class="menu__link menu__link--special" href="/busca/">
          <span class="menu__link--special-icon">
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1ZM0 7C0 6.44772 0.447715 6 1 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H1C0.447715 8 0 7.55228 0 7ZM0 13C0 12.4477 0.447715 12 1 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H1C0.447715 14 0 13.5523 0 13Z" fill="#F07E31"/>
            </svg>
          </span>                
          Ver tudo
        </a>
        <div class="menu__sub menu__sub--seeAll">
          <ul class="menu__list">
            ${categories
							.map(departament => {
								if (departament.hasChildren) return `
                  <li class="menu__item ${
                    departament.children.length > 0 ? `menu__item--dad menu__item--seAll` : ''
                  }">
                    <a class="menu__link menu__link--no-border" href="${departament.url}">
                      ${departament.name}
                    </a>
                    ${
                      departament.children.length > 0
                        ? `
                      <div class="menu__sub">
                        ${this.renderChildren(departament).outerHTML}
                      </div>
                    `
                        : ''
                    }
                  </li>
                `
							})
							.join('')}
          </ul>
        </div>
      </li>
    `
		);
    
    categories.map(departament => {
      if(departament.hasChildren) {
        this.listElement().insertAdjacentHTML("beforeend", `
            <li class="menu__item ${departament.children.length > 0 ? `menu__item--dad` : ''}">
              <a class="menu__link ${departament.children.length > 0 ? `js-menu-sub` : ''}" href="${departament.url}">
                ${departament.name}
              </a>
              ${departament.children.length > 0 ? `
                <div class="menu__sub">
                  <div class="menu__header">
                    <a class="menu__back js-menu-back" href="${departament.url}">
                      <svg class="menu__icon menu__icon--back" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#FCF3EA"/>
                        <path d="M12.125 15.75C11.9688 15.9062 11.9688 16.125 12.125 16.2812L16.7188 20.9062C16.8438 21.0625 17.0938 21.0625 17.25 20.9062L17.8438 20.2812C18 20.125 18 19.9062 17.8438 19.75L14.1562 16L17.8438 12.2812C18 12.125 18 11.875 17.8438 11.75L17.25 11.125C17.0938 10.9688 16.8438 10.9688 16.7188 11.125L12.125 15.75Z" fill="#F07F30"/>
                      </svg>

                      ${departament.name}
                    </a>
                    <a class="menu__link menu__link--blue" href="${departament.url}">
                      Ver todos
                    </a>
                  </div>
                  ${this.renderChildren(departament).outerHTML}
                </div>
              `:''}
            </li>
          `
        );
      }
    });
    
    this.handlerSub();
  }

  handlerSub() {
    const subMenuElements = this.getAllElements(this.subClass);
    const backElements = this.getAllElements(this.backClass);

    if(window.innerWidth < 769) {
      subMenuElements.forEach(sub => {
        sub.addEventListener('click', _event => {
          _event.preventDefault();
          
          sub.parentNode.classList.add(this.subActiveClass)
        });
      });

      backElements.forEach(back => {
        back.addEventListener('click', _event => {
          _event.preventDefault();

          back.parentNode.parentNode.parentNode.classList.remove(this.subActiveClass);
        });
      });
    }
  }

  load() {
    this.open();
    this.renderDepartament();
  }
}

