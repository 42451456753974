import Component from "./b8one.component";
import SearchRepository from "Repositories/b8one.searchRepository";
import ProductRepository from "Repositories/b8one.productRepository";
import ProductCardComponent from "./b8one.productCardComponent";

export default class SearchComponent extends Component {
  constructor() {
    super();

    this.searchRepository_ = new SearchRepository();
    this.productRepository_ = new ProductRepository();

    this.productCardComponent_ = new ProductCardComponent();

    this.inputClass = '.js-search-input';
    this.buttonClass = '.js-search-button';
    this.termsListClass = '.js-search-terms-list';
    this.productsListClass = '.js-search-product-list';
    this.buttonLinkClass = '.js-search-link';
    this.searchOrangeButton = '.search__button--orange';
    this.buttonCloseClass = '.js-search-close';
    this.buttonAddClass ='.js-search-product-button';
    this.searchActiveClass = 'action__search';

    this.searchTerm = '';
    
    /**
     * Get Input Search Element
     */
    this.inputElement = () => this.getElement(this.inputClass);

    /**
     * Get Product List in Search Element
     */
    this.productListElement = () => this.getElement(this.productsListClass);

    /**
     * Get Terms List in Search Element
     */
    this.termsListElement = () => this.getElement(this.termsListClass);

    /**
     * Get Button Link Search Element
     */
    this.buttonLinkElement = () => this.getElement(this.buttonLinkClass);

    /**
     * Get Orange Button Link Search Element
     */

    this.buttonOrangeElement = () => this.getElement(this.searchOrangeButton);

    /**
     * Get Button Close Search Element
     */
    this.buttonCloseElement = () => this.getElement(this.buttonCloseClass);

    
    this.productListPlaceholder = this.productListElement().innerHTML;
    this.termsListPlaceholder = this.termsListElement().innerHTML;
  }

  async get(data) {
    const res = await this.searchRepository_.searchTerm(data);

    this.dataHandler(res);
  }

  inputHandler() {
    let timer;

    const input = this.inputElement();

    input.addEventListener('keyup', ({ key }) => {
      clearTimeout(timer);

      const { value } = input;

      if(key === 'Enter') window.location.href = `/${value}`

      if(value.length >= 3 && key !== 'Control' && key !== 'Alt' && key !== 'Shift') {
        this.setPlaceholder();
        timer = setTimeout(() => {
          document.body.classList.add(this.searchActiveClass);
          this.searchTerm = value;
          
          this.get(value);
          this.setLinkButton();

          if(key === 'Enter') window.location.href = `/${value}`
        }, 500);
      } else {
        document.body.classList.remove(this.searchActiveClass);
      }
    }); 
  }

  async dataHandler(data) {
    let productsId = '';
    let termsProperties = []

    console.log(data);
    
    if(data.length) {
      data.forEach(async ({items, name, href}) => {
        if(items.length) {
          productsId += `&fq=productId:${items[0].productId}`;
        } else {
          termsProperties.push({name, href});
        }
      });

      console.log('render', data);
      
      await this.renderProducts(productsId);
      this.renderTerms(termsProperties);
      this.setLinkButton();
      this.buttonHandler();
    } else {
      this.renderEmpty();
    }
  } 

  closeHanlder() {
    this.buttonCloseElement().addEventListener('click', _event => {
      _event.preventDefault();

      this.inputElement().value = '';
      document.body.classList.remove(this.searchActiveClass);
      this.setPlaceholder();
    })
  }

  buttonHandler() {
    const buttonElements = this.getAllElements(this.buttonAddClass);

    buttonElements.forEach(button => {
      button.addEventListener('click', async _event => {
        _event.preventDefault();
        const { productId } = button.dataset;

        button.classList.add('searchProduct__button--loading');
        
        await this.productCardComponent_.addToCart(productId);

        button.classList.remove('searchProduct__button--loading');
        button.classList.add('searchProduct__button--success');
      });
    });
  }

  async renderProducts(params) {
    const products = await this.productRepository_.getByParams(params);
    let productsHTML = '';

    console.log('renderProducts', products);

    products.forEach(({productName, items, productId, link}) => {
      const { images, sellers } = items[0];

      productsHTML += `
        <li class="searchProduct__item"> 
          <a class="searchProduct__link" href="${link}">
            <div class="searchProduct__image">
              <img src="${images[0].imageUrl}" alt="${productName}" />
            </div>
            <div class="searchProduct__info">
              <span class="searchProduct__name">${productName}</span>
              <span class="searchProduct__price">
                ${this.helper_.formatMoney(sellers[0].commertialOffer.ListPrice)}
              </span>
            </div>
          </a>
          <a href="javascript:;" class="searchProduct__button js-search-product-button" data-product-id="${productId}">
            Comprar
          </a>
        </li>
      `;
    });

    this.productListElement().innerHTML = '';
    this.productListElement().insertAdjacentHTML('beforeend', productsHTML);
  }
  
  renderTerms(terms) {
    this.termsListElement().innerHTML = '';

    terms.forEach(({name, href}) => {
      this.termsListElement().insertAdjacentHTML('beforeend',  `
        <li class="searchTerms__item">
          <a href="${href}" class="searchTerms__link">
            <span class="searchTerms__link--strong">
              ${this.searchTerm}
            </span>
            ${name.replace(this.searchTerm, '')}
          </a>
        </li>
      `)
    });
  }

  setLinkButton() {
    this.buttonLinkElement().href = `/${this.searchTerm}`;
    this.setOrangeButtonLink()
  }

  setOrangeButtonLink() {
    this.buttonOrangeElement().addEventListener('click', _event => {
      window.location.href = `/${this.searchTerm}`;
    })
  }

  renderEmpty() {
    this.termsListElement().innerHTML = '';
    this.productListElement().innerHTML = 'Não encontramos resultados para sua busca';
  }
  
  setPlaceholder() {
    this.productListElement().innerHTML = this.productListPlaceholder;
    this.termsListElement().innerHTML = this.termsListPlaceholder;
  }

  load() {
    this.inputHandler();
    this.closeHanlder();
  }
}