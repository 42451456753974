import HttpClient from "../services/b8one.httpClient";

/**
 * Search data access layer
 */
class SearchRepository {
  /**
   * SearchRepository constructor
   * @param {*} httpClient
   */
  constructor(httpClient = new HttpClient()) {
    this._httpClient = httpClient;
    this._VTEX_API_ENDPOINT = "/buscaautocomplete/?productNameContains=";
  }

  /**
   * Get autocomplete by term
   * @param {*} term
   * @return {*}
   */
  async searchTerm(term) {
    return await this._httpClient
      .get(`${this._VTEX_API_ENDPOINT}${term}`)
      .then(result => {
        return result.itemsReturned;
      });
  }
}

export default SearchRepository;
