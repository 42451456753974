import NewsletterApp from "../application/newsletterApp";
import Component from './b8one.component';

class NewsletterComponent extends Component {
    constructor() {
        super();

        /** @private @const {NewsletterApp} */
        this.newsletterApp_ = new NewsletterApp();

        /** HTML Selectors */
        this.formSelector = '.b8one-js-newsletterForm';
        this.inputNameSelector = '.b8one-js-newsletterName';
        this.inputEmailSelector = '.b8one-js-newsletterEmail';
        this.buttonSubmitSelector = '.b8one-js-newsletterButton';
        this.newsletterCheckBox = '.b8one-js-newsletterCheckbox';
        this.newsletterCheckBoxLabel = '.newsletter__label';

        /**
         * Get Form's element
         */
        this.getFormElement = () => {
            return this.getElement(this.formSelector);
        }

        /**
         * Get name's input element
         */
        this.getInputNameElement = () => {
            return this.getElement(this.inputNameSelector);
        }

        /**
         * Get email's input element
         */
        this.getInputEmailElement = () => {
            return this.getElement(this.inputEmailSelector);
        }

        /**
         * Get submit button's element
         */
        this.getButtonSubmitElement = () => {
            return this.getElement(this.buttonSubmitSelector);
        }

        /**
         * Get checkbox element
         */
        this.getCheckboxElement = () => {
            return this.getElement(this.newsletterCheckBox)
        }

        /**
         * Get checkbox's label element
         */
        this.getCheckboxLabel = () => {
            return this.getElement(this.newsletterCheckBoxLabel)
        }

        /**
         * Calls checkbox label handler
         */
        this.checkBoxClickedHandler();
    }

    checkBoxClickedHandler() {
        const checkbox = this.getCheckboxElement();
        const checkboxLabel = this.getCheckboxLabel();

        if(checkbox && checkboxLabel) {
            checkboxLabel.addEventListener('click', () => {
                checkbox.checked = !checkbox.checked;
                this.disableSubmitHandler();
            });
        }
    }

    disableSubmitHandler() {
        const checkbox = this.getCheckboxElement();
        const submitButton = this.getButtonSubmitElement();

        if(submitButton) {
            if(!checkbox.checked) {
                submitButton.setAttribute("disabled", "true");
            } else submitButton.removeAttribute("disabled");
        }
    }

    async load() {
        const inputNameElement = this.getInputNameElement();
        const inputElement = this.getInputEmailElement();
        const formElement = this.getFormElement();
        const buttonSubmitElement = this.getButtonSubmitElement();

        if (inputNameElement && inputElement && formElement && buttonSubmitElement) {
            buttonSubmitElement.addEventListener('click', async (event) => {
                event.preventDefault();
                
                const loadingClassName = 'newsletter__button--loading';
                const doneClassName = 'newsletter__button--done';
                const errorClassName = 'newsletter__button--error';

                event.target.classList.remove(doneClassName);
                event.target.classList.add(loadingClassName);
                event.target.setAttribute("disabled", "disabled");

                const isFormValid = formElement.reportValidity();
                if (isFormValid) {
                    const result = await this.newsletterApp_.enableUserNewsletterByEmail(inputElement.value, inputNameElement.value);                    
                    if(result) {
                        event.target.classList.remove(loadingClassName);
                        event.target.classList.add(doneClassName);
                    } else {
                        event.target.classList.remove(loadingClassName);
                        event.target.classList.add(errorClassName);
                    }
                } else {
                    event.target.classList.remove(loadingClassName);
                    event.target.classList.add(errorClassName);
                }

                event.target.removeAttribute("disabled");
            }, false);
        } else {
            this.logger_.info(`
                Newsletter Component - Can't find some form elements... 
                Please check if the template file has the following elements:
                \n - name input selector:          '${this.inputNameSelector}',
                \n - input selector:          '${this.inputEmailSelector}', 
                \n - submit button selector:  '${this.formSelector}'
                \n - form selector:           '${this.formSelector}'`);
        }
    }
}

export default NewsletterComponent;
