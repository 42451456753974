/* eslint-disable max-len */

import MasterdataRepository from "./b8one.masterdataRepository";

/**
 * MasterdataUserRepository
 */
class MasterdataUserRepository extends MasterdataRepository {
  /**
   * class constructor
   */
  constructor() {
    super("CL");
    this.fieldsFilter = "userId,email";
  }

  /**
   * Get user by E-mail
   * @param {*} userEmail
   * @return {*}
   */
  async getByEmail(userEmail) {
    // return this.search(`email=${userEmail}`);
    const result = await this.get(`email=${userEmail}`);
    return result[0];
  }

  /**
   * Get user by id
   * @param {*} userId
   * @return {*}
   */
  async getById(userId) {
    const result = await this.get(`userId=${userId}`);
    return result[0];
  }

  /**
   * Get document filtered by param
   * @param {*} param
   */
  async get(param) {
    return await this.search(`_fields=${this.fieldsFilter}&_where=${param}`);
  }

  /**
   * Set newsletter
   * @param {*} email
   * @return {*}
   */
  async setUserNewsletterByEmail(email = "", name = "") {
    try {
      const res = await this.getByEmail(email);

      let newsletterPayload = {
        firstName: name,
        isNewsletterOptIn: true
      };

      if (res && res.length > 0) {
        newsletterPayload.id = res[0].id;
        const updateResult = await this.update(newsletterPayload);
        return updateResult;
        // console.log('updateResult', updateResult);
      } else {
        newsletterPayload.email = email;
        const addResult = await this.add(newsletterPayload);
        // console.log('addResult', addResult);
        return addResult;
      }
    } catch (error) {
      console.error(
        `Ocorreu um erro ao cadastrar newsletter para o email '${email}'`,
        error
      );
    }
  }
}

export default MasterdataUserRepository;
