/* eslint no-console: 0 */

/**
 * Logger Abstraction class
 */
class Logger {
  /**
   * Logger constructor
   * @param {string} logName Application name to log
   */
  constructor(logName = 'B8ONE Logger Component') {
    this.logName = logName;
  }

  /**
   * Log error message
   * @param {*} message - Message text
   * @param {*} errorObj - Message object
   */
  error(message = '', errorObj = {}) {
    console.error(message, errorObj);
  }

  /**
   * Log information message
   * @param {*} message - Message text
   */
  info(message = '') {
    console.info(message);
  }

  /**
   * Log warning message
   * @param {*} message - Message text
   */
  warning(message = '') {
    console.warn(this.logName, message);
  }

  /**
   * Generic log
   * @param {String} message - log's message
   */
  log(message = '') {
    console.log(message);
  }

  /**
   * Generic log
   * @param {String} message - log's message
   * @param {*} object - object to be logged
   */
  log(message = '', object = {}) {
    console.log(message, object);
  }
}

export default Logger;
