/* eslint-disable max-len */
import {Logger} from './';

/**
 * Helper Abstraction class
 */
class Helper {
  /**
   * Helper constructor
   * @param {*} props
   */
  constructor(props) {
    this._logger = new Logger('Ensure');
    this.props = props || {
      language: navigator.language || navigator.userLanguage,
      currency: 'BRL',
      currencyLocale: 'pt-br',
      minimumFractionDigits: 2,
    };
  }

  /**
   * format currency
   * @param {*} value
   * @param {*} minimumFractionDigits
   * @return {*}
   */
  formatPrice(value) {
    value = value / 100;
    value = (value * 1).toFixed(Math.max(0, ~~this.props.minimumFractionDigits));

    return new Intl.NumberFormat(this.props.currencyLocale, {
      style: 'currency',
      currency: this.props.currency,
      minimumFractionDigits: this.props.minimumFractionDigits,
    }).format(value);
  }

  formatMoney(value) {
    return new Intl.NumberFormat(this.props.currencyLocale, {
      style: 'currency',
      currency: this.props.currency,
      minimumFractionDigits: this.props.minimumFractionDigits,
    }).format(value);
  }

  sortObject(valueA, valueB) {
    if (valueA.name < valueB.name) {
      return -1;
    }
    if (valueA.name > valueB.name) {
      return 1;
    }
    return 0;
  }
}

export default Helper;
