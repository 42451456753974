import HttpClient from "./b8one.httpClient"

const VTEX_API_ENDPOINT = '/api/catalog_system/pub/category/tree';

export default class VtexCategoryService {
  httpClient_

  constructor(httpClient) {
    this.httpClient_ = httpClient ?? new HttpClient();
  }

  getAll(childrenNodes = 3) {
    const endpoint = `${VTEX_API_ENDPOINT}/${childrenNodes}`;
    return this.httpClient_.get(endpoint);
  }

  getAllWithProducts() {
    throw new Error('Method not implemented!!')
  }
}