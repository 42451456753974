/* eslint-disable max-len */
import { Logger, Ensure } from '../common';
import { Application } from '../application';
import { Helper } from 'Commons/index';

/**
 * Base Component class
 */
class Component {
  /**
   * Base Component constructor
   * @param {Object} props Component's properties.
   */
  constructor() {
    this.app_ = new Application();
    this.logger_ = new Logger();
    this.helper_ = new Helper();
    this.ensure_ = Ensure;

    // TODO: Remover esse ponto após alterar nos demais componentes que usam essa propriedade apartir..
    this.props = this.app_.props;
  }

  /**
   * Component rendering
   * @param {String} _contentContainerSelector - Content's Conatainer selector.
   * @param {String} _htmlToRender - HTML to be render.
   */
  render(_contentContainerSelector, _htmlToRender) {
    const element = document.querySelector(String(_contentContainerSelector));

    if (element) {
      element.innerHTML = String(_htmlToRender);
    } else {
      this.logger_.info(`element '${_contentContainerSelector}' not found on page!`);
    }
  }

  /**
   * Get element by selector
   * @param {*} elementSelector 
   */
  getElement(elementSelector = '') {
    return document.querySelector(String(elementSelector));
  }

  /**
   * Get elements by selectors
   * @param {*} elementSelector 
   */
  getElements(elementSelectors = []) {
    let elements = [];
    [...elementSelectors].forEach((selector) => {
      const elementFound = document.querySelector(String(selector));

      if (elementFound) {
        elements.push(elementFound);
      }
    });

    return elements;
  }

  /**
   * Get all elements by selector
   * @param {*} elementSelector 
   */
  getAllElements(elementSelector = '') {
    return [...document.querySelectorAll(String(elementSelector))];
  }
}

export default Component;
