import MasterdataUserRepository from '../repositories/b8one.masterdataUserRepository';
import Ensure from '../common/b8one.ensure';

/**
 * Newslleter Service's class
 */
class NewslleterService {
    constructor() {
        /** @private @const {MasterdataUserRepository} */
        this.masterdataUserRepository_ = new MasterdataUserRepository();
    }

    /**
     * Set user's newsletter by e-mail
     * @param {*} email User's email
     */
    async setNewsletterByEmail(email = '', name = '') {
        if (!Ensure.isUndefined(email)) {
            return await this.masterdataUserRepository_.setUserNewsletterByEmail(email, name);
        }
    }
}

export default NewslleterService;
